<template>
  <div class="download">
    <sideBarTwo></sideBarTwo>
    <div class="banner">
      <div class="banner_in">
        <img src="../assets/download_img_bg470.png" alt="">
        <div class="banner_main">
          <div class="item">
            <div class="er"><img src="../assets/download.png" alt=""></div>
            <div>律兜APP</div>
            <div>下载</div>
          </div>
          <div class="item">
            <div class="er"><img src="../assets/ilvdo.jpg" alt=""></div>
            <div>微信</div>
            <div>小程序</div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="item">
        <div class="word">
          <div>服务多元</div>
          <div>咨询、文书、案件...满足全方位需求</div>
        </div>
        <img src="../assets/download_img_serve.png" alt="">
      </div>
      <img src="../assets/download_img_line.png" alt="">
      <div class="item item2">
        <img src="../assets/download_img_answer.png" alt="">
        <div class="word">
          <div>快速响应</div>
          <div>平均6秒响应，7*24小时在线服务</div>
        </div>
      </div>
      <img src="../assets/download_img_line.png" alt="">
      <div class="item">
        <div class="word">
          <div>私人律师</div>
          <div>图文/电话不限次，1对1专属服务</div>
        </div>
        <img src="../assets/download_img_lawyer.png" alt="">
      </div>
    </div>
    <FootBox></FootBox>
  </div>
</template>
<script>
  import sideBarTwo from '@/components/sideBarTwo'
  import FootBox from '@/components/FootBox'
  export default {
    name: 'download',
    data() {
      return {
        menuActive: false
      }
    },
    mounted() {
      _hmt.push(['_trackPageview', '/download'])
    },
    methods: {
      mouseoverFun() {
        this.menuActive = true
      },
      mouseleaveFun() {
        this.menuActive = false
      }
    },
    components: {
      FootBox,
      sideBarTwo
    }
  }

</script>

<style scoped lang="scss">
  .header {
    width: 940px;
    height: 60px;
    margin: 0 auto;
    line-height: 60px;
    position: relative;

    .left {
      >img {
        margin-right: 60px;
        vertical-align: middle;
      }

      .menu {
        display: inline-block;
        vertical-align: middle;

        >div {
          display: inline-block;
          font-size: 18px;
          color: #333333;
          margin-right: 49px;
          position: relative;
          cursor: pointer;
        }

        >div:nth-child(2) {
          >img:nth-child(2) {
            position: absolute;
            top: 10px;
            right: -17px;
          }

          >img:nth-child(1) {
            position: relative;
            top: 2px;
          }
        }

        >div:hover {
          color: #21D0C8;
          font-weight: bold;
        }
      }
    }

    .right {
      position: absolute;
      top: 0;
      right: 0;

      img {
        vertical-align: middle;
        margin-right: 8px;
      }

      span {
        vertical-align: middle;
        font-size: 16px;
        font-weight: bold;
        color: #21D0C8;
      }
    }
  }

  .banner {
    background: #C0E8F7;

    .banner_in {
      width: 940px;
      height: 470px;
      margin: 0 auto;
      position: relative;
    }

    .banner_main {
      position: absolute;
      width: 940px;
      top: 230px;
      left: 0;
      text-align: center;

      .item {
        display: inline-block;
        font-size: 18px;
        color: #FFFFFF;

        .er {
          width: 140px;
          height: 140px;
          background: #FFFFFF;
          box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.3100);
          border-radius: 10px;
          box-sizing: border-box;
          padding: 8px;
          margin-bottom: 18px;

          img {
            width: 124px;
            height: 124px;
          }
        }

      }

      .item:nth-child(1) {
        margin-right: 80px;

        .er {
          padding: 0;

          img {
            width: 140px;
            height: 140px;
            border-radius: 10px;
          }
        }
      }
    }
  }

  .main {
    width: 630px;
    margin: 0 auto;

    >img {
      display: block;
    }

    .item {
      .word {
        display: inline-block;
        vertical-align: middle;
        color: #333333;
        margin: 0 55px 0 20px;

        >div:nth-child(1) {
          font-size: 36px;
          margin-bottom: 20px;
        }

        >div:nth-child(2) {
          font-size: 18px;
        }
      }

      >img {
        vertical-align: middle;
      }
    }

    .item2 .word {
      margin: 0 0 0 96px;
    }
  }

</style>
<style lang="scss">


</style>
